<template>
  <div class="detail-cards">
    <div class="detail-cards__inner">
      <div class="detail-cards__content">
        <div class="detail-cards__section" v-if="!isAdminType">
          <AccessFunctionality
                  :user="UEAdmin.data.user"
                  :userAccess="UEAdmin.data.userAccess.data"
                  :userAccessModel="UEAdmin.data.userAccess"
          />
        </div>
        <div class="detail-cards__section" v-if="!isAdminType">
          <UsersPresets
                  :user="UEAdmin.data.user"
                  :orderFees="orderFees"
                  :orderFBAFees="orderFBAFees"
                  :shipStation="shipStation"
                  :userPresets="UEAdmin.data.userPresets"
                  :UEAdmin="UEAdmin"
          />
        </div>
        <div class="detail-cards__section" v-if="!isAdminType">
          <Shippers
                  :user="UEAdmin.data.user"
                  :userDelivery="UEAdmin.data.userDelivery.data"
          />
        </div>
        <div class="detail-cards__section">
          <Documents
              :isAdminType="isAdminType"
                  :user="UEAdmin.data.user"
                  :userObject="UEAdmin.data.user.user"
                  :userDocuments="UEAdmin.data.userDocuments"
                  :userAccess="UEAdmin.data.userAccess.data"
                  :userAccessModel="UEAdmin.data.userAccess"
              :UEAdmin="UEAdmin"
          />
        </div>
        <div class="detail-cards__section detail-cards__section--btn">
          <div class="detail-cards__section-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
            <router-link :to="$store.getters.GET_PATHS.mainSettingsUsers"
                         class="site-link site-link--alt brown">
              {{$t('common_cancel.localization_value.value')}}
            </router-link>
          </div>
          <div class="detail-cards__section-col">
            <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_update'])"></div>
              <MainButton
                  class="sharp"
                  :value="$t('common_update.localization_value.value')"
                  @click.native="$emit('changeUser')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="detail-cards__aside">
        <div class="detail-cards__aside-inner">
          <UserCard
                  v-if="Object.keys(UEAdmin.data.user.user).length > 0"
                  :UEAdmin="UEAdmin"
                  :user="UEAdmin.data.user"
                  @reload="$emit('reload')"
                  @saveUser="$emit('saveUser')"
          />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import AccessFunctionality from "../../chunks/AccessFunctionality/AccessFunctionality";
  import UsersPresets from "../../chunks/UsersPresets/UsersPresets";
  import Shippers from "../../chunks/Shippers/Shippers";
  import Documents from "../../chunks/Documents/Documents";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import UserCard from "../../chunks/UserCard/UserCard";

  export default {
    name: "UsersEditAdmin",

    components: {
      AccessFunctionality,
      UsersPresets,
      Shippers,
      Documents,
      MainButton,
      UserCard,
    },

    props: {
      UEAdmin: Object,
      orderFees: Array,
      orderFBAFees: Array,
      shipStation: Array,
      isAdminType: Boolean,
    },

  }
</script>

<style scoped>

</style>
